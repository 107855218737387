import { useMemo, useRef, useState } from "react";
import { FormifyInputStyle } from "../../../../assets/styles";
import { editSplit } from "../../../../components/WeGantt/WeGantt";
import Moment from "../../../../modules/Moment/Moment";
import req from "../../../../modules/Request";
import { clsx } from "../../../../modules/Utilkit/Utilkit";
import { useNotify } from "../../../components/Notify";


const SplitEditForm = ({ task, split, setTasks, onClose, holidays, workingHours }) => {
  const { notify } = useNotify();
  
  const [ start, setStart ] = useState(split.start);
  const [ end, setEnd ] = useState(split.end);
  const [ duration, setDuration ] = useState(split.duration);

  const calculateTimeoutRef = useRef(undefined);

  const [ min, max ] = useMemo(() => {
    const index = task.dates.findIndex(d => d.id === split.id);

    const min = task.dates[ index - 1 ]?.end ?? undefined;
    const max = task.dates[ index + 1 ]?.start ?? undefined;

    return [ min, max ];
  }, [ task, split ]);

  const reqData = useMemo(() => {
    return editSplit(
      workingHours,
      holidays,
      task,
      split,
      start,
      end,
      duration,
      duration == split.duration ? (
        "move"
      ) : (
        "resize"
      )
    );
  }, [ start, end, duration ]);



  const handleSubmit = (e) => {
    e.preventDefault();

    req("sync-task", { task: reqData }).then((res) => {
      if (res.error) {
        notify(res.error, 'Errore durante la modifica del task.', 'error');
      }
    });
    setTasks(tasks => tasks.map(t => t.id === reqData.id ? { ...reqData, moved: 1 } : t));
    onClose();
  };

  return (
    <form className="min-w-[220px] flex flex-col items-center text-center" onSubmit={ handleSubmit }>
      <div className="w-full font-semibold border-b border-gray-400">Modifica Split</div>
      
      <div className="flex flex-col justify-center items-center space-y-1 pb-2">
        <div className="flex flex-col w-full">
          <label>Data Inizio</label>

          <input
            className={ FormifyInputStyle }
            required={ true }
            type="date"
            min={ min }
            onInvalid={ (e) => {
              e.preventDefault();
              notify('Data Inizio', e.target.validationMessage, 'error');
            } }
            value={ start }
            onInput={ (e) => {
              if (!holidays.includes(e.target.value)) {
                setStart(e.target.value);

                calculateTimeoutRef.current && clearTimeout(calculateTimeoutRef.current);

                if (new Date(e.target.value) != 'Invalid Date' && new Date(e.target.value) > new Date('2000-01-01')) {
                  setEnd(new Moment(e.target.value).end(duration / workingHours, holidays));
                }
              } else {
                notify('Data Inizio', "La data non può essere un giorno festivo.", 'error');
              }
            } }
          />
        </div>

        <div className="flex flex-col w-full">
          <label>Data Fine</label>

          <input
            className={ FormifyInputStyle }
            required={ true }
            type="date"
            min={ start }
            max={ max }
            onInvalid={ (e) => {
              e.preventDefault();
              notify('Data Fine', e.target.validationMessage, 'error');
            } }
            value={ end }
            onInput={ (e) => {
              if (!holidays.includes(e.target.value)) {
                setEnd(e.target.value);
                setDuration(new Moment(start).duration(e.target.value, { blacklist: holidays }) * workingHours);
              } else {
                notify('Data Fine', "La data non può essere un giorno festivo.", 'error');
              }
            } }
          />
        </div>

        <div className="flex flex-col w-full">
          <label>Durata (Ore)</label>

          <input
            className={ FormifyInputStyle }
            required={ true }
            type="number"
            min={ 0 }
            value={ duration }
            onInput={ (e) => {
              setDuration(+e.target.value);
              setEnd(new Moment(start).end(Math.ceil(+e.target.value / workingHours), holidays));
            } }
          />
        </div>

        <button
          className={ clsx(
            "border disabled:bg-green-300 disabled:border-green-200 rounded-sm",
            "px-4 py-0.5 duration-75 bg-green-500 hover:bg-green-400 border-green-400",
            "hover:border-green-300 text-white"
          ) }
        >
          Applica
        </button>
      </div>
    </form>
  );
};

export default SplitEditForm;