// src/config/index.js
let config;

if (process.env.REACT_APP_STAGE === 'dev') {
  config = require('./costants/dev').default;
} else if (process.env.REACT_APP_STAGE === 'test') {
  config = require('./costants/test').default;
} else if (process.env.REACT_APP_STAGE === 'prod') {
  config = require('./costants/prod').default;
} else {
  throw new Error(`Unknown environment: ${process.env.REACT_APP_STAGE}`);
}

export default config;