class Moment {
  #pValue;

  constructor(..._params) {
    let lValue;

    if (!_params || _params.length === 0) {
      lValue = new Date().toISOString().split('T')[0];
    } else if(_params.length === 1) { 
      const date = new Date(_params[0]);
      
      if (isNaN(date.getTime())) {
        console.error('[Moment] - value: Invalid Date');
        lValue = 'Invalid Date';
      } else {
        lValue = date.toISOString().split('T')[0];
      }
    } else {
      const [year, month = 1, day = 1] = _params;
      const date = new Date(+year, +month - 1, +day);

      if (isNaN(date.getTime())) {
        console.error('[Moment] - value: Invalid Date');
        lValue = 'Invalid Date';
      } else {
        lValue = date.toISOString().split('T')[0];
      }
    }

    this.#pValue = lValue;
  }
  
  value(...params) {

    if (!params || params.length === 0) {
      return this.#pValue;
    } else if(params.length === 1) {
      const date = new Date(params[0]);
      date.setHours(12, 0, 0, 0);

      if (isNaN(date.getTime())) {
        console.error('[Moment] - value: Invalid Date');
        this.#pValue = 'Invalid Date';
      } else {
        this.#pValue = date.toISOString().split('T')[0];
      }
    } else {
      const [year, month = 1, day = 1] = params;
      const date = new Date(+year, +month - 1, +day);
      date.setHours(12, 0, 0, 0);
  
      if (isNaN(date.getTime())) {
        console.error('[Moment] - value: Invalid Date');
        this.#pValue = 'Invalid Date';
      } else {
        this.#pValue = date.toISOString().split('T')[0];
      }
    }

    return this;
  }

  date(offset = 12) {
    const _date = new Date(this.#pValue);
    _date.setHours(offset, 0, 0, 0);
    return _date;
  }

  number() {
    return parseInt(this.#pValue.split('-').join(''));
  }

  forEach(to, callback = () => { }) {
    const _to = new Moment(to).date();
    const _from = new Date(this.#pValue);

    for (let date = _from; date <= _to; date.setDate(date.getDate() + 1)) {
      callback(date, date.toISOString().split('T')[ 0 ]);
    }

    return this;
  }

  map(to, callback = () => { }, _options = {}) {
    const { every = 'day' } = _options;

    if(new Date(to).getTime() < this.date().getTime()) {
      console.error('[EDate] - map: Invalid range');
      return;
    }

    if (every === 'day') {
      const _to = new Moment(to);
      const result = [];

      for (let date = new Moment(this.#pValue); date.date(12) <= _to.date(12); date.add(1)) {
        result.push(callback(date));
      }

      return result;
    } else if (every === 'week') {
      const _to = new Moment(to);
      const result = [];

      for (let date = new Moment(this.#pValue); date.date() <= _to.date(); date.add(7)) {
        result.push(callback(date));
      }
    } else if (every === 'month') {
      const _to = new Moment(to);
      const result = [];

      for (let date = new Moment(this.#pValue); date.date() <= _to.date(); date.add(1, 'months')) {
        date.value() !== this.#pValue && date.first('date');
        result.push(callback(date));
      }

      return result;
    }
  }

  reduce(to, callback = () => { }, initialValue) {
    if (typeof to === 'string') {
      const _to = new Date(to);
      _to.setHours(12, 0, 0, 0);
      let acc = initialValue;

      for (let date = new Moment(this.#pValue); date.date() <= _to; date.add(1)) {
        acc = callback(acc, date);
      }

      return acc;
    } else if (typeof to === 'number') {
      let acc = initialValue;

      const date = new Moment(this.#pValue);

      for (let i = 0; i <= to; i++) {
        acc = callback(acc, date);
        date.add(1, 'days');
      }

      return acc;
    }
  }

  add(value, type = 'days') {
    if (type === 'days') {
      const _date = this.date();
      _date.setHours(12, 0, 0, 0);
      _date.setDate(_date.getDate() + value);
      this.value(_date.toISOString().split('T')[ 0 ]);
    } else if (type === 'months') {
      const _date = this.date();
      _date.setHours(12, 0, 0, 0);
      _date.setMonth(_date.getMonth() + value);
      this.value(_date.toISOString().split('T')[ 0 ]);
    } else if (type === 'years') {
      const _date = this.date();
      _date.setFullYear(_date.getFullYear() + value);
      this.value(_date.toISOString().split('T')[ 0 ]);
    } else {
      console.error('[EDate] - add: Invalid type');
    }

    return this;
  }

  first(type = 'date') {
    if (type === 'date') {
      const _date = this.date();
      _date.setDate(1);
      this.value(_date.toISOString().split('T')[ 0 ]);
    } else if (type === 'month') {
      const _date = this.date();
      _date.setMonth(0);
      this.value(_date.toISOString().split('T')[ 0 ]);
    } else if (type === 'year') {
      const _date = this.date();
      _date.setMonth(0);
      _date.setDate(1);
      this.value(_date.toISOString().split('T')[ 0 ]);
    } else if (type === 'day') {
      // set to monday of the week
      const _date = this.date();
      _date.setDate(_date.getDate() - this.day());
      this.value(_date.toISOString().split('T')[ 0 ]);
    } else {
      console.error('[EDate] - first: Invalid type');
    }

    return this;
  }

  last(type = 'date') {
    if (type === 'date') {
      const _date = this.date();
      _date.setMonth(_date.getMonth() + 1);
      _date.setDate(0);
      this.value(_date.toISOString().split('T')[ 0 ]);
    } else if (type === 'month') {
      const _date = this.date();
      _date.setMonth(11);
      this.value(_date.toISOString().split('T')[ 0 ]);
    } else if (type === 'year') {
      const _date = this.date();
      _date.setMonth(11);
      _date.setDate(31);
      this.value(_date.toISOString().split('T')[ 0 ]);
    } else {
      console.error('[EDate] - last: Invalid type');
    }

    return this;
  }

  day() {
    const _date = this.date();
    return _date.getDay() === 0 ? 6 : _date.getDay() - 1;
  }

  week(range = 'year') {
    if (range === 'month') {
      const day = this.get('date');
      const week = Math.ceil(day / 7);

      return week;
    } else {
      const date = new Date(this.#pValue);
      const day = date.getUTCDay() || 7; // Get the day of the week (Monday = 1, Sunday = 7)
      date.setUTCDate(date.getUTCDate() + 4 - day); // Adjust to nearest Thursday
      const yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
      const weekNumber = Math.ceil((((date - yearStart) / 86400000) + 1) / 7);

      return weekNumber;
    }
  }

  left(target = 'day', relative = 'year') {
    if (target === 'day' && relative === 'year') {
      return this.duration(this.get('year') + '-12-31');
    } else if (target === 'week' && relative === 'month') {
      return new Moment(this.get('year'), +this.get('month'), 0).week('month') - this.week('month');
    } else {
      console.error('[EDate] - left: Invalid target or relative');
    }
  }

  monthdays() {
    return new Date(this.get('year'), this.get('month') + 1, 0).getDate();
  }

  get(type = 'date') {
    if (type === 'date') {
      return this.date().getDate();
    } else if (type === 'month') {
      return this.date().getMonth();
    } else if (type === 'year') {
      return this.date().getFullYear();
    } else {
      console.error('[EDate] - get: Invalid type');
    }
  }


  duration(to, options = {}) {
    const { blacklist = undefined, size = 'day' } = options;
    if (size === 'weeks') {
      const end = new Moment(to);

      const years = end.get('year') - this.get('year');

      let weeks = 1;

      if (years === 0) {
        weeks += end.week() - this.week();
      } else {

        for (let i = 0; i <= years; i++) {
          const currentYear = this.get('year') + i;

          if (this.get('year') === currentYear) {
            weeks += 52 - this.week();
          } else if (end.get('year') === currentYear) {
            weeks += end.week();
          } else {
            weeks += 52;
          }
        }
      }

      return weeks;

    } else if (size === 'months') {
      const start = new Date(this.#pValue);
      const end = new Date(to);
      if (blacklist) {
        const sievedBlacklist = blacklist.filter(date => new Date(date) >= start && new Date(date) <= end);
        return +(Math.floor((end - start) / (1000 * 60 * 60 * 24 * 30)) - sievedBlacklist.length);
      } else {
        return +Math.floor((end - start) / (1000 * 60 * 60 * 24 * 30));
      }
    } else {
      const start = new Date(this.#pValue);
      const end = new Date(to);
      if (blacklist) {
        const sievedBlacklist = blacklist.filter(date => new Date(date) >= start && new Date(date) <= end);
        return +(Math.floor((end - start) / (1000 * 60 * 60 * 24)) - sievedBlacklist.length);
      } else {
        return +Math.floor((end - start) / (1000 * 60 * 60 * 24));
      }
    }
  }

  end(duration, blacklist = undefined) {
    if (duration <= 0) {
      return this.#pValue;
    }

    if (blacklist) {
      const start = new Date(this.#pValue);
      let presumedEnd;
      let sievedBlacklist;
      let acc = 0;
      do {
        presumedEnd = new Moment(this.#pValue).add(Math.ceil(duration - 1 + acc), 'days').date();
        sievedBlacklist = blacklist.filter(date => new Date(date) >= start && new Date(date) <= presumedEnd);
        if (sievedBlacklist.length > acc) {
          acc = sievedBlacklist.length;
        } else {
          break;
        }
      }
      while (true);

      const trueDuration = Math.ceil(duration - 1 + acc);
      const trueEnd = new Moment(this.#pValue).add(trueDuration, 'days').available(blacklist, "next");

      return trueEnd;
    } else {
      const date = new Moment(this.#pValue);
      date.add(Math.ceil(duration - 1));
      return date.value();
    }
  }

  start(duration, blacklist = undefined) {
    if (blacklist) {
      const date = new Moment(this.#pValue);

      let acc = duration;
      for (let i = 1; i < acc; i++) {
        date.add(-1);
        if (blacklist.includes(date.value())) {
          acc++;
        }
      }

      return date.value();
    } else {
      const date = new Moment(this.#pValue);
      date.add(-duration + 1);
      return date.value();
    }
  }

  available(blacklist, verse = "next") {
    if (verse !== "next" && verse !== "prev") {
      console.error('[EDate] - available: Invalid verse');
      return;
    }

    const date = new Moment(this.#pValue);
    while (blacklist.includes(date.value())) {
      if (verse === "next") {
        date.add(1);
      } else {
        date.add(-1);
      }
    }

    return date.value();
  }

  monthname(names = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ]) {
    return names[ this.get('month') ];
  }

  dayname(names = [ "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday" ]) {
    return names[ this.day() ];
  }

  isToday(range = 'day') {
    if (range === 'week') {
      const isCurrentMonth = this.get('month') == new Date().getMonth()
        && this.get('year') == new Date().getFullYear();

      if (!isCurrentMonth) return false;

      const isCurrentWeek = new Moment(this.#pValue).add(-this.day()).reduce(6, (acc, date) => {
        if (acc) return acc;
        return date.value() == new Date().toISOString().split('T')[ 0 ];
      }, false);

      return isCurrentWeek;
    } else if (range === 'month') {
      const isCurrentMonth = this.get('month') == new Date().getMonth()
        && this.get('year') == new Date().getFullYear();

      return isCurrentMonth;
    } else {
      return this.#pValue == new Date().toISOString().split('T')[ 0 ];
    }
  }

  same(date, range = 'day') {
    // date is a string
    // range: day, month, year

    if (range === 'month') {
      return this.get('month') == new Date(date).getMonth()
        && this.get('year') == new Date(date).getFullYear();
    } else if (range === 'year') {
      return this.get('year') == new Date(date).getFullYear();
    } else {
      return this.#pValue == date;
    }
  }

  weeksInMonth() {
    const start = new Moment(this.#pValue).first('date');
    const end = new Moment(this.#pValue).last('date');
    let initialValue = 0;

    for (let date = start; date.date() <= end.date(); date.add(1)) {
      if (date.day() === 0) {
        initialValue++;
      }
    }

    return initialValue;
  }
};


export default Moment;